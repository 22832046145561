<!--
    Created by 程雨喵'mac on 2024/9/2.
    Copyright © 2024年 云柜-金星晖. All rights reserved.
    功能概述：计费模板详情
-->
<style lang="less">
</style>

<template>
  <div class="bill-template-detail">
    <Form>
      <FormItem
        v-for="(item, i) in inputItems"
        :key="i"
        :label="item.label + ':'"
        :label-width="item.labelWidth"
        :required="item.isRequired"
      >
        <RadioGroup
          v-if="item.tagName === 'Radio'"
          v-model="model[item.key]"
          @on-change="onChangeRadio"
        >
          <Radio
            v-for="(sub, r) in item.data"
            :key="'r-' + r"
            :disabled="!isEdit"
            :label="sub.value"
          >
            <span>{{ sub.label }}</span>
          </Radio>
        </RadioGroup>
        <Input
          v-else
          v-model.trim="model[item.key]"
          :placeholder="item.placeholder"
          :disabled="!isEdit"
          class="display-flex-1"
          @input="checkSubmit"
        />
      </FormItem>
    </Form>
    <v-use-power
      v-if="model.category === 1"
      ref="usePowerRef"
      :is-edit="isEdit"
      :data-list="model.configs"
    />
    <v-use-electric
      v-else-if="model.category === 2"
      ref="useElectricRef"
      :is-edit="isEdit"
      :data-list="model.configs"
    />
    <v-use-time
      v-else-if="model.category === 3 && model.configs.length"
      ref="useTimeRef"
      :is-edit="isEdit"
      :data-list="model.configs"
    />
    <div
      v-else
      class="display-flex display-flex-v-center display-flex-h-center"
      style="height: 180px"
    >
      敬请期待^^
    </div>
  </div>
</template>

<script>
import vUsePower from '@/pages/050__deviceManager/chargingPileList/view/setting/priceSetting/usePower.vue';
import vUseElectric from '@/pages/050__deviceManager/chargingPileList/view/setting/priceSetting/useElectric.vue';
import vUseTime from '@/pages/050__deviceManager/chargingPileList/view/setting/priceSetting/useTime.vue';
import DevicePriceModel from '@/pages/050__deviceManager/chargingPileList/model/DevicePriceModel';
export default {
  components: { vUsePower, vUseElectric, vUseTime },
  props: {
    model: {
      type: Object,
      default: () => {
        return {};
      }
    },
    isEdit: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      inputItems: [
        { label: '计费模板名称', labelWidth: 120, placeholder: '请输入计费模版名称', key: 'name', isRequired: true },
        {
          label: '计费方式',
          tagName: 'Radio',
          labelWidth: 120,
          placeholder: '',
          key: 'category',
          isRequired: true,
          data: [
            { value: 1, label: '按功率计费' },
            { value: 2, label: '按电量计费' },
            { value: 3, label: '按时间计费' }
          ]
        }
      ],
      orgDataList: []
    };
  },
  created () {
    if (this.isEdit) {
      this.checkSubmit();
    }
  },
  methods: {
    checkSubmit () {
      const item = this.inputItems.find(v => !this.model[v.key]);
      this.$emit('on-change', {
        disabled: !!item,
        msg: item ? (item.label + '不可为空') : ''
      });
    },
    onChangeRadio () {
      if (this.model.category === 1) {
        this.model.configs = DevicePriceModel.createInitList();
      } else if (this.model.category === 2) {
        this.model.configs = DevicePriceModel.createInitListByDian();
      } else if (this.model.category === 3) {
        this.model.configs = DevicePriceModel.createInitListByTime();
      }
      this.checkSubmit();
    },
    // 外部调用
    $checkSubmitDataValid () {
      // 按功率计费
      if (this.model.category === 1) {
        const ele = this.$refs.usePowerRef;
        if (ele.utilsPowerCheckEmpty() && ele.utilsPowerCheckValue() && ele.utilsTimeCheckEmpty()) {
          return this.model.checkValidAddSubmit();
        }
        return DevicePriceModel.getValidRes(false);
      } else if (this.model.category === 2) {
        if (this.$refs.useElectricRef.$checkSubmit().success) {
          // 成功
          return this.model.checkValidAddSubmitByDian();
        }
        return DevicePriceModel.getValidRes(false);
      } else if (this.model.category === 3) {
        if (this.$refs.useTimeRef.$checkSubmit().success) {
          // 成功
          return this.model.checkValidAddSubmitByTime();
        }
        return DevicePriceModel.getValidRes(false);
      }
      return DevicePriceModel.getValidRes(false, '计费方式暂不支持');
    }
  }
};
</script>
