<!--
    Created by 王丽莎 on 2024/08/29.
    Copyright © 2023年 云柜-金星晖. All rights reserved.
    界面名称：运营管理-充电桩计费模版-模版下发记录
-->
<style lang="less">
#billing-template-record {
  .text-color-error {
    color: @errorColor;
    font-weight: 600;
  }
}
</style>

<template>
  <yg-drawer
    :show-bottom="false"
    width="90%"
    :back-type="1"
    title="模版下发记录"
    @on-close="_close"
  >
    <div
      id="billing-template-record"
      slot="content"
    >
      <div class="setting-area">
        <yg-setting-area
          :items="settingItems"
          :total="page.total"
          @on-click="_clickSetting"
        />
      </div>
      <div
        v-permission="'system:admin:template:getComputeFeeIssueList'"
        class="padding-primary-top"
      >
        <yg-table
          :columns="tableColumns"
          :data="tableData"
          :page="page"
          @on-page-change="onChangePage"
        />
      </div>
      <v-template
        v-if="dialog.templateModel"
        :model="dialog.templateModel"
        @on-close="dialog.templateModel = null"
      />
    </div>
  </yg-drawer>
</template>

<script>
import { BillingTemplateDetailModel, BillingTemplateRecordListModel } from '../model/BillingTemplateDataModel';
import ColumnMixin from '../mixin/recordTableColumn';
import vTemplate from '../view/template.vue';
import OperationsApiSet from '@/request/api/OperationsApiSet';
import DownloadApiSet from '@/request/api/DownloadApiSet';

export default {
  name: 'BillingTemplateRecord',
  components: { vTemplate },
  mixins: [ColumnMixin],
  props: {
    model: {
      type: Object,
      default: () => {
        return {};
      }
    }
  },
  data () {
    return {
      settingItems: [
        BaseSettingModel.initData('刷新列表', 'refresh', 'md-refresh').setPermission('system:admin:template:getComputeFeeIssueList')
      ],
      page: { current: 1, total: 0 },
      tableData: [],
      dialog: {
        templateModel: null
      }
    };
  },
  created () {
    this.requestData();
  },
  methods: {
    /** ******************* 工具方法开始 ******************* **/
    /** ******************* 工具方法结束 ******************* **/

    /** ******************* 回调事件开始 ******************* **/
    onChangePage (page) {
      this.page.current = page;
      this.requestData();
    },
    _clickSetting (key) {
      console.log(key);
      if (key === 'refresh') {
        this.page.current = 1;
        this.requestData();
      }
    },
    /** ******************* 回调事件结束 ******************* **/

    /** ******************* 点击事件开始 ******************* **/
    _close () {
      this.$emit('on-close');
    },
    /** ******************* 点击事件结束 ******************* **/

    /** ******************* 数据请求开始 ******************* **/
    // 请求列表
    requestData () {
      this.tableData = [];
      this.page.total = 0;
      const api = OperationsApiSet.getComputeFeeIssueList;
      api.params = {
        pageSize: this.$config.pageSize,
        pageNo: this.page.current
      };
      this.$http(api).then(res => {
        const resData = res.data;
        this.page.total = resData.total;
        this.tableData = BillingTemplateRecordListModel.initListWithDataList(resData.rows);
        // const resData = BillingTemplateRecordListModel.createFakeDataList();
        // this.tableData = BillingTemplateRecordListModel.initListWithDataList(resData);
      });
    },
    // 请求详情
    requestDetail (templateId, key) {
      const api = OperationsApiSet.getComputeFee;
      api.params = {
        templateId
      };
      console.log(api);
      this.$http(api).then(res => {
        const resData = res.data;
        this.dialog[key] = BillingTemplateDetailModel.initModelWithData(resData);
        // const resData = BillingTemplateDetailModel.createFakeData();
        // this.dialog[key] = BillingTemplateDetailModel.initModelWithData(resData);
      });
    },
    // 请求导出
    requestDownload (id) {
      if (this.tableData.length === 0) {
        return this.$Message.warning({
          title: '无数据可导出',
          content: '请先查询到结果后再进行导出',
          okText: '我知道了'
        });
      }
      const api = DownloadApiSet.downloadCreate;
      api.params = {
        reportType: 29,
        reportCondition: {
          templateConfigRecordId: id
        }
      };
      this.$http(api).then(res => {
        this.$Modal.confirm({
          title: '操作成功',
          content: '请至下载中心导出',
          okText: '立即前往',
          cancelText: '稍后前往',
          onOk: () => {
            this.$router.push({
              name: 'download_list'
            });
          }
        });
      });
    }
    /** ******************* 数据请求结束 ******************* **/
  }
};
</script>
