<!--
    Created by 程雨喵'mac on 2024/9/2.
    Copyright © 2024年 云柜-金星晖. All rights reserved.
    功能概述：计费模版下发
-->
<style lang="less">
.bill-temp-issue-dialog {
  display: flex;
  align-items: flex-start;
  height: 100%;
  padding-bottom: @containerGap;
  .issue-left {
    height: 100%;
    .flex-grow(480px);
    background-color: @backColorStrong;
    border-radius: @borderRadiusMid;
    overflow: auto;
  }
  .issue-right {
    position: relative;
    margin-left: @containerGap;
    flex: 1;
    min-width: 800px;
    height: 100%;
    overflow: auto;
    background-color: @backColorStrong;
    border-radius: @borderRadiusMid;
  }
}
</style>

<template>
  <yg-drawer
    title="下发模板"
    :back-type="2"
    width="100%"
    :show-bottom="false"
    @on-close="onClose"
  >
    <div
      slot="content"
      class="bill-temp-issue-dialog"
    >
      <div class="issue-left">
        <yg-card
          title="当前计费模板"
        >
          <div slot="content">
            <v-detail
              ref="templateDetail"
              :is-edit="false"
              :model="model"
            />
          </div>
        </yg-card>
      </div>
      <div class="issue-right">
        <yg-card
          title="指定下发范围"
        >
          <div slot="content">
            <v-choose-block
              :issue-type="1"
              :template-id="model.templateId"
              @on-finish="onFinish"
            />
          </div>
        </yg-card>
      </div>
    </div>
  </yg-drawer>
</template>

<script>
import vDetail from './detail.vue';
import vChooseBlock from '@/pages/108__operationsManager/billingTemplate/view/submit/chooseBlock.vue';
export default {
  components: { vDetail, vChooseBlock },
  props: {
    model: {
      type: Object,
      default: () => {
        return {};
      }
    }
  },
  data () {
    return {};
  },
  methods: {
    onIssue () {},
    onClose () {
      this.$emit('on-close');
    },
    onFinish (goToRecord) {
      this.$emit('on-close', goToRecord);
    },
    requestData () {}
  }
};
</script>
